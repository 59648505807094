import { useRouter } from 'next/router';
import Script from 'next/script';
import React from 'react';

import { getBreakpoint } from '@cssVariable/breakpoint';
import { isSuspendBarReason } from '@hooks/Routes/getAllRoutesWithAccess';
import { externalRoutes } from '@hooks/Routes/routes';
import { useUser } from '@hooks/useUser';
import { getCookie } from '@utils/getCookie';

const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? '';

const updateIntercom = (params: Intercom_.IntercomSettings | undefined) => {
  if (typeof window.Intercom === 'function') {
    // eslint-disable-next-line new-cap
    window.Intercom('update', params);
  }
};
export const showLiveChat = () => {
  if (typeof window.Intercom === 'function') {
    // eslint-disable-next-line new-cap
    window.Intercom('show');
  }
};

export const LiveChat: React.FC = () => {
  const { user } = useUser();
  const [admin, setAdmin] = React.useState(false);

  const router = useRouter();
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const adminCookie = getCookie('admin');

    setAdmin(adminCookie === 'true');
  }, []);

  const getIsLauncherVisible = () =>
    typeof window !== 'undefined' && window.innerWidth > getBreakpoint('lg') && !admin;

  React.useEffect(() => {
    const visible =
      !user.account_info.suspension_reason ||
      isSuspendBarReason(user.account_info.suspension_reason) ||
      user.account_info.suspension_reason === 'clients_request';

    setIsVisible(visible);
  }, [user.account_info.suspension_reason, admin]);

  const userData = user.isLoggedIn
    ? {
        app_id: intercomAppId,
        user_hash: user.userHashHmac,
        email: user.account_info.email_address,
        user_id: user.account_info.customer_id.toString(),
        kox_id: `https://kox.cz/${user.account_info.customer_id}`,
        hide_default_launcher: !getIsLauncherVisible(),
      }
    : { app_id: intercomAppId, hide_default_launcher: !getIsLauncherVisible() };

  React.useEffect(() => {
    updateIntercom(userData);
  }, [router.pathname]);

  React.useEffect(() => {
    const chatTimeout = setTimeout(() => {
      setIsVisible(true);
    }, 3000);

    return () => {
      clearTimeout(chatTimeout);
    };
  }, []);

  React.useEffect(() => {
    updateIntercom(userData);
  }, [user]);

  React.useEffect(() => {
    if (isVisible) {
      // We don't know when Intercom intializes, let's wait for it using setInterval
      const intercomBootInterval = setInterval(() => {
        if (typeof window.Intercom === 'function') {
          if (userData) {
            // eslint-disable-next-line new-cap
            window.Intercom('boot', userData);

            if (router.query.chat !== undefined) {
              window.Intercom('showNewMessage');
            }
          }
          clearInterval(intercomBootInterval);
        }
      }, 300);
      return () => clearInterval(intercomBootInterval);
    }
    return () => undefined;
  }, [isVisible, router.query.chat]);

  if (isVisible) {
    return (
      <Script src={`${externalRoutes.intercomWidget}/${intercomAppId}`} strategy="lazyOnload" />
    );
  }

  return null;
};
