import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React from 'react';

export type ITimerange = {
  from: Dayjs;
  to: Dayjs;
};

const defaultNow = dayjs();
const defaultTimerange = { from: defaultNow.subtract(1, 'day'), to: defaultNow };

export const isWholeMonth = (timerange: ITimerange) =>
  timerange.from.isSame(timerange.from.startOf('month')) &&
  timerange.to.isSame(timerange.from.endOf('month'));

export const getTimerangeDiff = (timerange: ITimerange) =>
  timerange.to.diff(timerange.from, 'seconds');

type ITimerangeContext = {
  now: Dayjs;
  timerange: ITimerange;
  setTimerange: (timerange: ITimerange, now: Dayjs) => void;
  secondaryTimerange: {
    timerange: ITimerange | null;
    setTimerange: (timerange: ITimerange | null) => void;
  };
  inUtcTimezone: boolean;
};

const TimerangeContext = React.createContext<ITimerangeContext>({
  now: defaultNow,
  timerange: defaultTimerange,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTimerange: () => {},
  secondaryTimerange: {
    timerange: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTimerange: () => {},
  },
  inUtcTimezone: false,
});

interface IProps {
  initialData?: { timerange: ITimerange; secondaryTimerange?: ITimerange | null; now: Dayjs };
  inUtcTimezone?: boolean;
  children: React.ReactNode | ((props: ITimerangeContext) => React.ReactNode);
}

export const TimerangeProvider: React.FC<IProps> = ({
  initialData = { timerange: defaultTimerange, secondaryTimerange: null, now: defaultNow },
  inUtcTimezone = false,
  children,
}) => {
  const [now, setNow] = React.useState<Dayjs>(initialData.now);
  const [timerange, setTimerange] = React.useState<ITimerange>(initialData.timerange);
  const [secondaryTimerange, setSecondaryTimerange] = React.useState<ITimerange | null>(
    initialData.secondaryTimerange ?? null,
  );

  const handleTimerangeChange = (timerange: ITimerange, now: Dayjs) => {
    setTimerange(timerange);
    setNow(now);
  };

  return (
    <TimerangeContext.Provider
      value={{
        now,
        timerange,
        setTimerange: handleTimerangeChange,
        inUtcTimezone,
        secondaryTimerange: {
          timerange: secondaryTimerange,
          setTimerange: setSecondaryTimerange,
        },
      }}
    >
      {typeof children === 'function'
        ? children({
            timerange,
            setTimerange: handleTimerangeChange,
            now,
            inUtcTimezone,
            secondaryTimerange: {
              timerange: secondaryTimerange,
              setTimerange: setSecondaryTimerange,
            },
          })
        : children}
    </TimerangeContext.Provider>
  );
};

export const useTimerange = () => React.useContext(TimerangeContext);
