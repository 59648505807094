import { configureScope } from '@sentry/nextjs';
import React from 'react';

import { useUser } from '@hooks/useUser';
import type { IUserData } from '@lib/prefetchUserData';

const setSentryUserScope = (user: IUserData) => {
  configureScope(scope => {
    scope.setTag('account_type', user.account_info.is_main_account ? 'main' : 'subaccount');
    if (user.billing) {
      scope.setTag('payment_plan', user.billing.pricing_type);
    }
    scope.setTag('trial', user.trial !== undefined);
    scope.setUser({
      id: user.account_info.customer_id.toString(),
      email: user.account_info.email_address,
    });
  });
};

export const Sentry: React.FC = () => {
  const { user } = useUser();

  React.useEffect(() => {
    if (user.isLoggedIn) {
      setSentryUserScope(user);
    }
  }, [user]);

  return null;
};
