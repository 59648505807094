import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const keepUtcDate = (date: number | string) => {
  const dayjsDate = dayjs(date);

  return dayjs.utc(dayjsDate.add(dayjsDate.utcOffset() * -1, 'minute'));
};

export const getUtcDateFromLocal = (date: number | string) => dayjs.utc(date);

export const getDateInUtcTimezone = (date: number | string) => {
  const dayjsDate = dayjs(date);

  return dayjsDate.add(dayjsDate.utcOffset() * -1, 'minute').tz('GMT', false);
};

export const convertDateToUtcTimezone = (date: Dayjs) => {
  if (!date.isUTC()) {
    return date;
  }

  return date.tz('UTC');
};
