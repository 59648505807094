import NextDocument, { Html, Head, Main, NextScript } from 'next/document';
import React from 'react';

import { publicRoutes } from '@hooks/Routes/routes';

export const getTheme = (page: string) => {
  if (page === '/') return undefined;

  if (
    [
      publicRoutes.auth.login.pathname,
      publicRoutes.auth.resetPassword.pathname,
      publicRoutes.auth.verifyEmail.pathname,
      publicRoutes.customer.join.pathname,
      publicRoutes.customer.join.pathname,
    ].some(path => page.startsWith(path))
  ) {
    return 'dark-blue';
  }

  if (page.startsWith(publicRoutes.support.index.pathname)) {
    return 'light';
  }

  return undefined;
};

class Document extends NextDocument {
  render() {
    const pageProps = this.props?.__NEXT_DATA__?.props?.pageProps;

    return (
      <Html
        className={
          getTheme(this.props.__NEXT_DATA__.page) ?? pageProps.theme ?? pageProps.defaultTheme
        }
        lang="en-US"
      >
        <Head>
          <meta charSet="UTF-8" />

          <link href="https://use.typekit.net/vpx0jyc.css" rel="stylesheet" />
          <link href="https://fonts.gstatic.com" rel="preconnect" />
          <link
            href="https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;500;600&display=swap"
            rel="stylesheet"
          />

          <link href="/img/favicon/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180" />
          <link href="/img/favicon/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
          <link href="/img/favicon/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />
          <link href="/img/favicon/site.webmanifest" rel="manifest" />
          <link color="#ffe115" href="/img/favicon/safari-pinned-tab.svg" rel="mask-icon" />
          <meta content="#00125c" name="msapplication-TileColor" />
          <meta content="#000" name="theme-color" />
        </Head>
        <body>
          <Main />
          <div id="portal-root" />
          <NextScript />
        </body>
      </Html>
    );
  }
}

export default Document;
